import { motion } from 'framer-motion';

const NavSvg = () => {

    const isMobile = window.innerWidth <= 768;

  return (
<div>
    <motion.svg 
    fill="rgb(64, 149, 160)" 
    height={isMobile ? "30px" :"50px" }
    width="100px" 
    version="1.1" 
    id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    viewBox="0 0 511.147 511.147" 
    xmlSpace="preserve">
        <g transform="translate(1 1)">
            <g>
                <g>
                    <motion.path 
                        whileHover={{ fill: 'rgb(20, 88, 44)' }}
                        whileTap={{ fill: 'rgb(20, 88, 44)' }}
                    d="M506.733,247.785L319,111.252c-2.56-1.707-5.973-2.56-8.533-0.853c-2.56,1.707-4.267,4.267-4.267,7.68v85.333h-93.867
                        c-5.12,0-8.533,3.413-8.533,8.533v85.333c0,5.12,3.413,8.533,8.533,8.533H306.2v85.333c0,3.413,1.707,5.973,4.267,7.68
                        c1.707,0.853,2.56,0.853,4.267,0.853s3.413-0.853,4.267-1.707l187.733-136.533c1.707-1.707,3.413-4.267,3.413-6.827
                        C510.147,252.052,509.293,249.492,506.733,247.785z M323.267,374.079v-76.8c0-5.12-3.413-8.533-8.533-8.533h-93.867v-68.267
                        h93.867c5.12,0,8.533-3.413,8.533-8.533v-76.8L487.96,254.612L323.267,374.079z"/>
                    <motion.path 
                    whileHover={{ fill: 'rgb(33, 141, 71)' }}
                    whileTap={{ fill: 'rgb(33, 141, 71)' }}
                    d="M161.133,203.412c-14.507,0-25.6,11.093-25.6,25.6v51.2c0,14.507,11.093,25.6,25.6,25.6c14.507,0,25.6-11.093,25.6-25.6
                        v-51.2C186.733,214.505,175.64,203.412,161.133,203.412z M169.667,280.212c0,5.12-3.413,8.533-8.533,8.533
                        s-8.533-3.413-8.533-8.533v-51.2c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533V280.212z"/>
                    <motion.path 
                     whileHover={{ fill: 'rgb(55, 185, 100)' }}
                     whileTap={{ fill: 'rgb(55, 185, 100)' }}
                    d="M92.867,203.412c-14.507,0-25.6,11.093-25.6,25.6v51.2c0,14.507,11.093,25.6,25.6,25.6c14.507,0,25.6-11.093,25.6-25.6
                        v-51.2C118.467,214.505,107.373,203.412,92.867,203.412z M101.4,280.212c0,5.12-3.413,8.533-8.533,8.533
                        c-5.12,0-8.533-3.413-8.533-8.533v-51.2c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533V280.212z"/>
                    <motion.path 
                    whileHover={{ fill: 'rgb(83, 223, 132)' }}
                    whileTap={{ fill: 'rgb(83, 223, 132)' }}
                    d="M24.6,203.412c-14.507,0-25.6,11.093-25.6,25.6v51.2c0,14.507,11.093,25.6,25.6,25.6c14.507,0,25.6-11.093,25.6-25.6
                        v-51.2C50.2,214.505,39.107,203.412,24.6,203.412z M33.133,280.212c0,5.12-3.413,8.533-8.533,8.533s-8.533-3.413-8.533-8.533
                        v-51.2c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533V280.212z"/>
                </g>
            </g>
        </g>
    </motion.svg>
</div>
  )
}

export default NavSvg